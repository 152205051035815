<template>
  <div>
  
    <b-row>
      <b-col md="12">
        <div class="d-flex notation mb-1">
          <b-badge class="d-flex align-items-center justify-content-center" variant="light-primary">
            <b-form-checkbox v-model="tab.w" @input="handleTabClick($event, 'w')"       class="custom-control-primary"
>
            </b-form-checkbox>
            <span>Workout</span></b-badge
          >
          <b-badge class="d-flex align-items-center justify-content-center" variant="light-warning" >
            <b-form-checkbox v-model="tab.s" @input="handleTabClick($event, 's')" class="custom-control-warning">
            </b-form-checkbox
            ><span>Suppliment</span></b-badge
          >
          <b-badge class="d-flex align-items-center justify-content-center" variant="light-success">
            <b-form-checkbox v-model="tab.n" @input="handleTabClick($event, 'n')" class="custom-control-success">
            </b-form-checkbox
            ><span>Nutrition</span></b-badge
          >
        </div>
      </b-col>
    </b-row>
    <div class="app-calendar overflow-hidden border">
      <div class="row no-gutters">
        <!-- Sidebar -->
        <!-- <div
          class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
          :class="{'hide': isCalendarOverlaySidebarActive}"
        >
          <calendar-sidebar :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive" />
        </div> -->

        <!-- Calendar -->

        <div class="col">
          <div class="card shadow-none border-0 mb-0 rounded-0 overflow-auto">
            <div class="card-body pb-0 calander-cls">
              <full-calendar
                v-if="flag"
                ref="refCalendar"
                :options="calendarOptions"
                class="full-calendar"
              />
            </div>
          </div>
        </div>

        <!-- Sidebar Overlay -->
        <!-- <div
          class="body-content-overlay"
          :class="{'show': isCalendarOverlaySidebarActive}"
          @click="isCalendarOverlaySidebarActive = false"
        /> -->
        <!-- <calendar-event-handler
          v-model="isEventHandlerSidebarActive"
          :event="event"
          :clear-event-data="clearEventData"
          @remove-event="removeEvent"
          @add-event="addEvent"
          @update-event="updateEvent"
        /> -->
      </div>
    </div>
  </div>
</template>
  
  <script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import nutrition from "../../../../../public/svg/Nutrition.svg";
import workout from "../../../../../public/svg/Workout.svg";
import supplement from "../../../../../public/svg/Supplement.svg";
import { BRow, BCol, BBadge, BFormCheckbox } from "bootstrap-vue";
import { random } from 'lodash';
export default {
  props: ["calendar_data"],
  components: {
    FullCalendar,
    BRow,
    BBadge,
    BFormCheckbox,
    BCol, // make the <FullCalendar> tag available
    // CalendarSidebar,
    // CalendarEventHandler,
  },
  watch: {
    calendar_data() {
      this.handleEvents();
    },
  },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        initialView: "dayGridMonth",
        headerToolbar: {
          start: "sidebarToggle, prev,next, title",
          end: "dayGridMonth,timeGridWeek,timeGridDay,listYear",
        },
        navLinks: true,
        events: [],
        eventContent: this.handleEventsHtml,
        eventOrder: this.eventOrderFunc,
        // eventTextColor:'white',
      },
      flag: false,
      tab: { w: true, n: true, s: true },
    };
  },
  mounted() {
    this.handleEvents();
  },
  methods: {
    handleTabClick(e,item) {
      this.tab[item] = e;
      this.handleEvents();
    },
    eventOrderFunc() {
      return 1;
    },
    handleEventsHtml(e) {
      let icon = "";
      let html = "";
      switch (e.event._def.extendedProps.calendar) {
        case "Workout":
          icon = workout; // Assuming workout is the path to your workout SVG icon
          html= `<div class='overflow-hidden event-line'>
                  <img src="${icon}" alt="${e.event._def.title}" class="event-icon"/>
                  ${e.event._def.title ? e.event._def.title : ""}
                  <div>
                    <ul class='calander-ul'>
                      <li style='${e.event._def.extendedProps.sets?'':'display:none'}'>Sets: ${e.event._def.extendedProps.sets}</li>
                      <li style='${e.event._def.extendedProps.reps?'':'display:none'}'>Reps: ${e.event._def.extendedProps.reps}</li>
                      <li style='${e.event._def.extendedProps.time?'':'display:none'}'>Time: ${e.event._def.extendedProps.time} Min</li>
                    </ul>
                  </div>
                </div>`
          break;
        case "Supplement":
          icon = supplement; // Assuming supplement is the path to your supplement SVG icon
          break;
        case "Nutrition":
          icon = nutrition; // Assuming nutrition is the path to your nutrition SVG icon
          let list=''
          e.event._def.extendedProps.items?.map((item)=>{
            list+=`<p class='m-0 overflow-hidden'>${item.nutrition?item.nutrition.name:''}</p>
                    <ul class='calander-ul'>
                      <li>${item.quantity} ${item.nutrition&&item.nutrition.unit?item.nutrition.unit.name:''}</li>
                    </ul>
            `
          })
          html= `<div class='overflow-hidden event-line'>
                  <h5 class='text-success'><img src="${icon}" alt="${e.event._def.title}" class="event-icon"/>
                  ${e.event._def.title ? e.event._def.title : ""}</h5>

                  </div>
                  ${list}
                </div>`
          
          break;
        default:
          // Default icon or behavior if necessary
          break;
      }
      return {
        html: html?html:`<div class='overflow-hidden'><img src="${icon}" alt="${
          e.event._def.title
        }" class="event-icon"/>${
          e.event._def.title ? e.event._def.title : ""
        }</div>`,
      };
    },

    handleEvents() {
      let arr = [];
      let id = 1;
      if (this.tab.w) {
        this.calendar_data.workout_data?.map((item) => [
          item.exercises.map((inner) => {
            arr.push({
              title: `${inner.subexercise ? inner.subexercise.name : ""}`,
              // description: description.join(', '),
              id: id,
              date: item.date,
              extendedProps: {
                calendar: "Workout",
                sets:inner.sets,
                reps:inner.reps,
                time:inner.cardio_timing,
              },
              classNames: ["bg-light-primary"],
            });
            id++;
          }),
        ]);
      }

      if (this.tab.s) {
        this.calendar_data.all_suplements?.map((item) => {
          arr.push({
            title: `${item.supplement ? item.supplement.name : ""}`,
            id: id,

            date: item.date,
            extendedProps: {
              calendar: "Supplement",
            },
            classNames: ["bg-light-warning"],
          });
          id++;
        });
      }

      if (this.tab.n) {
        let meal={}
        this.calendar_data.nutrition?.sort((a,b)=> a.meal_id - b.meal_id)
        this.calendar_data.nutrition?.map((item) => {
          meal[item.date]=meal[item.date]?meal[item.date]:{}
          meal[item.date][item.meal_id]=meal[item.date][item.meal_id]?meal[item.date][item.meal_id]:[]
          meal[item.date][item.meal_id].push(item)

          // meal[item.date][item.meal_id].push({
          //   title: `${item.nutrition ? item.nutrition.name : ""}`,
          //   id: id,
          //   date: item.date,
          //   extendedProps: {
          //     calendar: "Nutrition",
          //     quantity:item.quantity,
          //     unit:item.nutrition && item.nutrition.unit ? item.nutrition.unit.name:'',
          //     meal:!meal[item.date][item.meal_id] && item.meal ? item.meal.name:'' 
          //   },
          //   classNames: ["bg-light-success"],
          // });
        });

        for(let date in meal){
          for(let item in meal[date]){
            arr.push({
              title: meal[date][item][0].meal.name,
              id: id,
              date: date,
              extendedProps: {
                calendar: "Nutrition",
                items:meal[date][item]
              },
            classNames: ["bg-light-success"],
            })
             id++;

          }
        }

      }

      this.calendarOptions.events = arr;
      this.flag = true;
    },
  },
};
</script>
  
  <style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";

.fc-scrollgrid-sync-table {
  width: 100% !important;
}
.fc-daygrid-body {
  width: 100% !important;
}
.fc-col-header {
  width: 100% !important;
}
.calander-cls {
  min-width: 1300px;
}
.notation {
  gap: 5px;
  font-size: 15px;
}
.notation span {
  padding: 7px 7px;
}
.calander-ul{
  padding-left: 20px;
}
</style>
  
<template>
  <div>
    <b-table :items="this.comparisonArray" responsive :fields="tableColumns">
      <template #cell(id)="data">
        <b-form-checkbox
          v-model="data.item.checked"
          name="is-vertical-menu-collapsed"
          class="mr-0"
          inline
          @input="handleCheck($event, data.item)"
        />
      </template>

      <template #cell(checkintime)="data">
        <div class="checkindate">
          <p>
            {{ moment(data.item.checkintime, "HH:mm:ss").format("hh:mm A") }}
          </p>
          <p class="d-flex">
            {{
              moment(data.item.checkindate, "yyyy-MM-DD").format("DD/MM/YYYY")
            }}
          </p>
        </div>
      </template>
      <template #cell(currentphoto)="data">
        <div class="d-flex flex-row" style="min-height:200px;min-width:600px">
          <div class='com-img p-1'>
            <b-img
              :src="data.item.frontpose"
              @click="handleOpenImage(data.item.frontpose)"
              variant="light"
              class="cursor-pointer"
            ></b-img>
          </div>
          <div class='com-img p-1'>
            <b-img
              :src="data.item.leftside"
              @click="handleOpenImage(data.item.leftside)"
              class="cursor-pointer"
              variant="light"
            ></b-img>
          </div>
          <div class='com-img p-1'>
            <b-img
              :src="data.item.rightside"
              @click="handleOpenImage(data.item.rightside)"
              class="cursor-pointer"
              variant="light"
            ></b-img>
          </div>
          <div class='com-img p-1'>
            <b-img
              :src="data.item.backside"
              @click="handleOpenImage(data.item.backside)"
              class="cursor-pointer"
              size="70"
              variant="light"
            ></b-img>
          </div>
        </div>
      </template>
      <template #cell(currentweight)="data">
        <div class="d-flex w-100 align-items-center">
          <p class="m-0 align-text-center">{{ data.item.currentweight }} Kg</p>
        </div>
      </template>
      <template #cell(currentchest)="data">
        <div class="d-flex w-100 align-items-center">
          <p class="m-0 align-text-center">{{ data.item.currentchest }} Cm</p>
        </div>
      </template>
      <template #cell(currentwaist)="data">
       <div class="d-flex w-100 align-items-center">
        <p class="m-0 ">{{ data.item.currentwaist }} Cm</p>
       </div>
      </template>
    </b-table>
  </div>
</template>
  
<script>
import {
  BButton,
  BTabs,
  BImg,
  BTab,
  BCardText,
  BTable,
  BCard,
  BAvatar,
  BPagination,
  BCol,
  BRow,
  BFormInput,
  BFormGroup,
  BProgress,
  BCardBody,
  BForm,
  BMedia,
  BMediaAside,
  BModal,
  BBadge,
  BSpinner,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import VueApexCharts from "vue-apexcharts";
import AppEchartStackedArea from "@core/components/charts/echart/AppEchartStackedArea.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Calendar from "./calendar/Calendar.vue";
import Workout from "./videoManagement/workoutVideoManagement.vue";
import { required } from "@validations";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";

export default {
  components: {
    BMedia,
    BMediaAside,
    BBadge,
    BCardText,
    BPagination,
    BSpinner,
    BModal,
    BCardBody,
    ValidationProvider,
    ValidationObserver,
    required,
    BTabs,
    BTab,
    BCard,
    BAvatar,
    BForm,
    BCol,
    BImg,
    BFormInput,
    BButton,
    BProgress,
    BRow,
    vSelect,
    BTable,
    BFormGroup,
    VueApexCharts,
    AppEchartStackedArea,
    Calendar,
    Workout,
    flatPickr,
    BFormCheckbox,
  },
  props: ["comparisonArray"],
  data() {
    return {
      moment,
      tableColumns: [
        {
          key: "checkintime",
          label: "CheckIn",
          sortable: true,
        },
        { key: "CurrentPhoto", label: "Photo" },
        { key: "currentweight", label: "Current Weight", sortable: true },
        {
          key: "currentwaist",
          label: "Current Waist",
          sortable: true,
        },
        {
          key: "currentchest",
          label: "Current Chest",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    handleOpenImage(item) {
      window.open(item, "_bank");
    },
  },
};
</script>
<style >
  .com-img{
    width: 25%;
  }
  .com-img img{
    max-width: 100%;
    max-height: 100%;
  }
</style>
<template>
  <div>
    <b-row>
      <b-col md="4">
        <b-card class="">
          <div class="profile-image p-0 d-flex justify-content-center">
            <b-avatar
              rounded="none"
              size="114"
              variant="light"
              :src="userData.profilephoto"
              style="
                width: 114px;
                height: 114px;
                object-fit: cover;
                border-radius: 10px;
              "
            />
          </div>
          <hr class="mb-2" />
          <!-- follower projects rank -->
          <h4 class="text-muted">Client Details</h4>
          <div
            v-if="isSideDataLoading"
            style="height: 400px"
            class="d-flex justify-content-center align-items-center"
          >
            <b-spinner label="Loading..."></b-spinner>
          </div>
          <div v-else>
            <b-col cols="12" class="pt-1 d-flex">
              <!-- {{ data }} -->
              <h6 class="lableTitle">Username:</h6>
              <p class="ml-1 mb-0 inputValue">{{ userData.f_name }}</p>
            </b-col>

            <b-col cols="12" class="pt-1 d-flex">
              <h6 class="inputValue font-weight-bolder">Email:</h6>
              <p class="ml-1 inputValue mb-0">
                {{ userData.email }}
              </p>
            </b-col>
            <b-col cols="12" class="pt-1 d-flex">
              <h6 class="inputValue font-weight-bolder">Gender:</h6>
              <p class="mb-0">
                <b-badge class="ml-1" variant="light-success">{{
                  userData.gender ? userData.gender.name : ""
                }}</b-badge>
              </p>
            </b-col>
            <b-col cols="12" class="pt-1 d-flex">
              <h6 class="font-weight-bolder inputValue">Date of Birth :</h6>
              <p class="ml-1 inputValue mb-0">
                {{
                  userData.dob ? moment(userData.dob).format("DD/MM/YYYY") : ""
                }}
              </p>
            </b-col>
            <b-col cols="12" class="pt-1 d-flex">
              <h6 class="inputValue font-weight-bolder">Injuries:</h6>
              <p class="inputValue ml-1 mb-0">
                {{ userData.injuries }}
              </p>
            </b-col>
            <b-col cols="12" class="pt-1 d-flex">
              <h6 class="inputValue font-weight-bolder">Country:</h6>
              <p class="inputValue ml-1 mb-0">
                {{ userData.country ? userData.country.name : "" }}
              </p>
            </b-col>
            <b-col cols="12" class="pt-1 d-flex">
              <h6 class="inputValue font-weight-bolder">Height :</h6>
              <p class="inputValue ml-1 mb-0">
                {{ userData.height }}
              </p>
            </b-col>
            <b-col cols="12" class="pt-1 d-flex">
              <h6 class="inputValue font-weight-bolder">Goal :</h6>
              <p class="inputValue ml-1 mb-0">
                {{ userData.goal_name }}
              </p>
            </b-col>
            <b-col cols="12" class="pt-1 d-flex">
              <h6 class="inputValue font-weight-bolder">Current weight:</h6>
              <p class="inputValue ml-1 mb-0">
                {{
                  userData && userData.customer_goal
                    ? userData.customer_goal.current_weight
                    : ""
                }}
              </p>
            </b-col>
            <b-col cols="12" class="pt-1 d-flex">
              <h6 class="inputValue font-weight-bolder">Goal weight:</h6>
              <p class="inputValue ml-1 mb-0">
                {{
                  userData && userData.customer_goal
                    ? userData.customer_goal.goal_weight
                    : ""
                }}
              </p>
            </b-col>

            <b-col cols="12" class="pt-1 d-flex" v-if='userData.weekly_goal'>
              <h6 class="inputValue font-weight-bolder">Weekly goal:</h6>
              <p class="inputValue ml-1 mb-0">
                {{ userData.weekly_goal }}
              </p>
            </b-col>
            <b-col cols="12" class="pt-1 d-flex">
              <h6 class="inputValue font-weight-bolder">Contact :</h6>
              <p class="inputValue ml-1 mb-0">
                {{ userData.phone }}
              </p>
            </b-col>
            <b-col cols="12" class="pt-1 d-flex">
              <h6 class="inputValue font-weight-bolder">
                Membership end date:
              </h6>
              <p class="inputValue ml-1 mb-0">
                {{
                  MembershipDate
                    ? moment(this.MembershipDate).format("DD/MM/YYYY")
                    : ""
                }}
              </p>
            </b-col>
            <b-col cols="12" v-if="!isProfileType">
              <b-button
                v-if="isMembershipDateAfterToday"
                class="ml-1"
                variant="primary"
                @click="openPlanModal()"
                size="sm"
                >Plan Renewal</b-button
              >
            </b-col>
            <b-col cols="12" class="pt-1 d-flex" v-if="isProfileType">
              <h6 class="inputValue font-weight-bolder">Suspended Reason:</h6>
              <p class="inputValue ml-1 mb-0">
                {{ userData.suspend_reason }}
              </p>
            </b-col>
            <b-col
              class="d-flex justify-content-between flex-wrap"
              v-if="!isProfileType"
            >
              <b-button
                @click="openSuspendModal()"
                variant="outline-danger"
                class="button mt-2"
              >
                Temporary Suspend
              </b-button>
              <b-button
                @click="openNewModal()"
                variant="outline-danger"
                class="button mt-2"
              >
                Suspend
              </b-button>
            </b-col>
          </div>
          <!--/ follower projects rank -->
        </b-card>
        <div>
          <b-modal
            v-model="suspened"
            no-close-on-backdrop
            hide-footer
            centered
            size="md"
          >
            <validation-observer ref="simpleRules">
              <b-form ref="myFormMain">
                <b-row class="p-1">
                  <b-col md="12" class="my-2 text-center">
                    <!-- {{ CustomerData }} -->
                    <h3
                      class="font-weight-bolder"
                      style="font-size:26px; font-family:Public Sans, sans's serif"
                    >
                      Suspend Client
                    </h3>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group>
                      <validation-provider #default="{ errors }" name="Reason">
                        <b-form-input
                          type="text"
                          v-model="SuspendReason"
                          maxlength="100"
                          placeholder="Write a reason"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group>
                      <label class="font-weight-bold mt-1" for="h-first-name"
                        >How long do you want to suspend</label
                      >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Weak"
                      >
                        <v-select
                          type="text"
                          v-model="suspendWeak"
                          :options="selectWeakOptions"
                          placeholder="Select Week"
                        ></v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- @click="Congo" -->
                  <b-col md="12" class="d-flex justify-content-center mt-2">
                    <b-button
                      type="submit"
                      @click.prevent="
                        handleTrainerSubmit('Temporary', userData.id)
                      "
                      variant="primary"
                      class="mr-1"
                      style="font-family: Public Sans"
                    >
                      Submit
                    </b-button>
                    <b-button
                      type="reset"
                      variant="primary"
                      @click="closemodel()"
                      style="font-family: Public Sans"
                    >
                      Cancel
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-modal>
        </div>
        <div>
          <b-modal
            v-model="Parmasuspended"
            no-close-on-backdrop
            hide-footer
            centered
            size="md"
          >
            <validation-observer ref="simple">
              <b-form ref="myFormMain">
                <b-row class="p-1">
                  <b-col md="12" class="my-2 text-center">
                    <!-- {{ CustomerData }} -->
                    <h3
                      class="font-weight-bolder"
                      style="font-size:26px; font-family:Public Sans, sans's serif"
                    >
                      Suspend Client
                    </h3>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group>
                      <validation-provider #default="{ errors }" name="Reason">
                        <b-form-input
                          type="text"
                          v-model="ParmaSuspendReason"
                          maxlength="100"
                          placeholder="Write a reason"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- @click="Congo" -->
                  <b-col md="12" class="d-flex justify-content-center mt-2">
                    <b-button
                      type="submit"
                      @click.prevent="
                        handleParmaSuspendTrainer('Permanent', userData.id)
                      "
                      variant="primary"
                      class="mr-1"
                      style="font-family: Public Sans"
                    >
                      Submit
                    </b-button>
                    <b-button
                      type="reset"
                      variant="primary"
                      @click="closemodel()"
                      style="font-family: Public Sans"
                    >
                      Cancel
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-modal>
        </div>
      </b-col>
      <b-col class="col-md-8">
        <b-tabs pills>
          <b-tab>
            <template #title>
              <span>Overall Details</span>
            </template>
            <b-card>
              <b-row>
                <b-col  cols="12" class="d-flex flex-wrap align-items-center">
                  <b-avatar
                  v-if="userData.trainer"
                    size="32"
                    :src="userData.trainer ? userData.trainer.profilephoto : ''"
                    class="mr-2"
                  />
                  <div >
                    <p class="m-0 font-weight-bold mt-1 UserProfileDetails">
                      {{ userData.trainer ? userData.trainer.f_name : "" }}
                    </p>
                    <small v-if="userData.trainer">{{ setSpecialization(userData.trainer) }}</small>
                  </div>
                  <div class="ml-auto mt-2 mt-md-0 mt-1 UserProfileDetails">
                    <b-button
                      variant="primary"
                      @click="openModal"
                      class="text-nowrap font-weight-bold"
                    >
                      Change Trainer
                    </b-button>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <h3
                    class="p-2 UserProfileDetails font-weight-bolder"
                    style="font-size: 18px"
                  >
                    Check-Ins
                  </h3>
                </b-col>
              </b-row>
              <b-row class="d-flex justify-content-between w-100 m-0">
                <!-- Per Page -->
                <b-col md="6" class="d-flex justify-content-start">
                  <label
                    style="margin-top: 5px"
                    class="mr-2 UserProfileDetails d-flex align-items-center"
                    >Show</label
                  >
                  <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                    v-model="per_page"
                    class="per-page-selector d-inline-block mx-50 mb-1"
                    :options="['5', '10', '20', '50', '100']"
                    @input="changePagination()"
                  />
                  <!-- Search -->
                </b-col>

                <b-col md="6" class="d-flex justify-content-end align-items-center">
                  <div>
                    <b-button
                      variant="primary"
                      class="mr-1 font-weight-bold text-large"
                      @click="resetDate()"
                    >
                      Cancel
                    </b-button>
                  </div>
                  <div class="d-flex align-items-center" style="width: 200px">
                    <flat-pickr
                    v-model="date"
                      :config="{ mode: 'range', dateFormat: 'd/m/Y' }"
                      style="background-color: rgb(239 239 238)"
                      class="form-control p-0 m-0 font-weight-bolder text-center cursor-pointer flat-picker text-dark border-0 shadow-none"
                      placeholder="DD/MM/YYYY"
                      @input="handleDateChange($event)"
                    />
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-table
                  ref="refUserListTable"
                  :busy="isSideDataLoading"
                  style="font-size: 13px"
                  class="mt-1 position-relative"
                  :items="details"
                  responsive
                  :fields="tableColumns"
                  primary-key="id"
                  show-empty
                  empty-text="No matching records found"
                  @sort-changed="onSortChanged"
                >
                  <!-- :sort-desc.sync="isSortDirDesc" -->
                  <template #table-busy>
                    <div class="text-center text-secondary my-2">
                      <b-spinner class="align-middle"></b-spinner>
                    </div>
                  </template>
                  <template #cell(id)="data">
                    <b-form-checkbox
                      v-model="data.item.checked"
                      name="is-vertical-menu-collapsed"
                      class="mr-0"
                      inline
                      @input="handleCheck($event, data.item)"
                    />
                  </template>

                  <template #cell(checkintime)="data">
                    <div class="checkindate">
                      <p>
                        {{
                          moment(data.item.checkintime, "HH:mm:ss").format(
                            "hh:mm A"
                          )
                        }}
                      </p>
                      <p class="d-flex">
                        {{
                          moment(data.item.checkindate, "yyyy-MM-DD").format(
                            "DD/MM/YYYY"
                          )
                        }}
                      </p>
                    </div>
                  </template>
                  <template #cell(currentphoto)="data">
                    <div class="d-flex flex-row" style="height: 50px">
                      <b-img
                        :src="data.item.frontpose"
                        @click='handleOpenImage(data.item.frontpose)'
                        size="70"
                        variant="light"
                        style="margin: 5px; height: 50px"
                        class="cursor-pointer"
                      ></b-img>
                      <b-img
                        :src="data.item.leftside"
                        @click='handleOpenImage(data.item.leftside)'
                        class="cursor-pointer"


                        size="70"
                        variant="light"
                        style="margin: 5px; height: 50px"
                      ></b-img>
                      <b-img
                        :src="data.item.rightside"
                        @click='handleOpenImage(data.item.rightside)'
                        class="cursor-pointer"


                        size="70"
                        variant="light"
                        style="margin: 5px; height: 50px"
                      ></b-img>
                      <b-img
                        :src="data.item.backside"
                        @click='handleOpenImage(data.item.backside)'
                        class="cursor-pointer"

                        size="70"
                        variant="light"
                        style="margin: 5px; height: 50px"
                      ></b-img>
                    </div>
                  </template>
                  <template #cell(CurrentWeight)="data">
                    <div class="d-flex w-100 align-items-center">
                      <p class="m-0 align-text-center">
                        {{ data.item.currentweight }} Kg
                      </p>
                    </div>
                  </template>
                   <template #cell(currentchest)="data">
                    <div class="d-flex w-100 align-items-center">
                      <p class="m-0 align-text-center">
                        {{ data.item.currentchest }} Cm
                      </p>
                    </div>
                  </template>
                  <template #cell(CurrentWaistMeasurement)="data">
                    <p>{{ data.item.currentwaist }} Cm</p>
                  </template>
                  <template #cell(WeaklyProgress)="data">
                    <div class="d-flex align-items-center">
                      <span
                        >{{
                          data.item.progress
                            ? data.item.progress.toFixed(2)
                            : 0
                        }}%</span
                      >
                      <div style="width: 60px">
                        <b-progress
                          :value="data.item.progress"
                          class="w-100 ml-1"
                          :variant="
                            data.item.progress < 50 ? 'danger' : 'success'
                          "
                        ></b-progress>
                      </div>
                    </div>
                  </template>
                  <template #cell(Remark)="data">
                    <p>{{ data.item.helpyouwith }}</p>
                  </template>
                </b-table>
                <div class="w-100 d-flex justify-content-between p-1">
                  <!-- Pagination -->
                  <div>
                    <span class="text-nowrap">
                      Showing {{ paginationStart }} to {{ paginationEnd }} of
                      {{ paginationTotal }}</span
                    >
                  </div>
                  <b-pagination
                    :total-rows="paginationTotal"
                    :per-page="per_page"
                    v-model="paginationValue"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                    @input="changePagination()"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </b-row>

              <b-modal
                hide-footer
                v-model="changetrainer"
                no-close-on-backdrop
                centered
                size="md"
              >
                <b-row class="p-2 w-100" >
                  <b-col md="12" class="d-flex align-items-center flex-column">
                    <h2
                      class="font-weight-bolder"
                      style="font-size:26px font-family: 'Public Sans', sans-serif;"
                    >
                      Clients assigned to trainers
                    </h2>
                    <p class="UserProfileDetails font-weight-bold">
                      "Assign to trainers with our gym management software."
                    </p>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group>
                      <label
                        style="font-weight: 500; margin-top: 10px"
                        for="h-trainer"
                        >Search Trainer</label
                      >
                      <b-form-input
                        @input="handleSearch()"
                        v-model="searchTrainer"
                        id="h-trainer"
                        placeholder="Name"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="12" v-if="isDataLoading">
                    <div
                      style="height: 200px"
                      class="d-flex justify-content-center align-items-center"
                    >
                      <b-spinner label="Loading..."></b-spinner>
                    </div>
                  </b-col>
                  <div v-else class="w-100">

                  <b-col
                    md="12"
                    v-for="(item, index) in allTrainer"
                    :key="index"
                    class="mt-2"
                  >
                    <div class="w-100">
                      <div class="d-flex w-100 align-items-center">
                        <div class="mr-1">
                          <b-avatar size="32" :src="item.profilephoto" />
                        </div>
                        <div class="w-100">
                          <h5 class="m-0 UserProfileDetails font-weight-bolder">
                            {{ item.f_name }}
                          </h5>
                          <small> {{ setSpecialization(item) }}</small>
                        </div>
                        <div
                          class="d-flex w-100 align-items-center justify-content-end"
                        >
                          <b-button
                            variant="primary"
                            :disabled='isDataLoading'
                            @click="selectTrainer(item.id)"
                          >
                            <span>Select</span>
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </b-col>
                </div>

                </b-row>

              </b-modal>
              <!-- <b-modal hide-footer centered size="sm" no-close-on-backdrop v-model="trainee">
                                <div>
                                    <h3>Congratulations</h3>
                                    <span> "Trainer added successfully! Welcome to our fitness community."</span>
                                </div>
                            </b-modal> -->
              <!-- <div cols="12" class="d-flex justify-content-end" >
                            <b-button variant="primary">
                                Continue
                            </b-button>
                        </div> -->
            </b-card>
          </b-tab>
          <b-tab>
            <template #title>
              <span>Progress</span>
            </template>
            <div>
              <b-card class="p-0 progressChart">
                <b-col md="12">
                  <h3 class="font-weight-bolder UserProfileDetails">
                    {{
                      userData.goal_name == "Gain Weight"
                        ? "Gain Weight"
                        : userData.goal_name == "Lose weight"
                        ? "Lose Weight"
                        : ""
                    }}
                  </h3>
                  
                  <p>
                    {{
                      userData.customer_goal
                        ? userData.customer_goal.weeklygainweight
                          ?'Goal : '+ userData.customer_goal.weeklygainweight.name
                          : userData.customer_goal.weeklyloseweight
                          ? 'Goal : '+userData.customer_goal.weeklyloseweight.name
                          : ""
                        : ""
                    }}
                  </p>
                </b-col>
                <b-col md="12" v-if="chartFlag">
                  <app-echart-stacked-area :optionData="option" />
                  <!-- style="width: 65rem; height: 600px" -->
                </b-col>
                <b-col
                  v-else
                  md="12"
                  class="d-flex justify-content-center align-items-center mt-5"
                >
                  <div
                    style="min-height: 200px"
                    class="d-flex justify-content-center align-items-center"
                  >
                    <b-spinner label="Loading..."></b-spinner>
                  </div>
                </b-col>
              </b-card>
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <span>Workout Plans</span>
            </template>
            <b-card>
              <div class="suppli-intake">
                <b-row
                  class="align-items-center mb-1"
                  v-for="(item, index) in supplementData"
                  :key="index"
                >
                  <b-col cols="12" md="8" class="d-flex align-items-center">
                    <!-- <b-img src="https://m.media-amazon.com/images/I/61oGyzpqVpS.jpg" style="width: 50px;" /> -->
                    <div class="ml-2">
                      <p class="m-0 UserProfileDetails font-weight-bolder">
                        Supplement Intake
                      </p>
                    </div>
                    <span class="ml-auto">{{ item.supplement.name }}</span>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                    class="d-flex justify-content-end align-items-center mt-2 mt-md-0"
                  >
                    <b-button
                      size="sm"
                      variant="outline-danger"
                      class="ml-2 font-weight-bold UserProfileDetails"
                      @click="removeSupplement(item.id)"
                    >
                      Remove
                    </b-button>
                  </b-col>
                </b-row>
              </div>

              <b-row class="align-items-center mb-1">
                <b-col
                  cols="12"
                  md="12"
                  class="d-flex justify-content-end align-items-center mt-md-0"
                >
                  <b-button
                    variant="primary"
                    size="sm"
                    @click="Supplement"
                    class="font-weight-bold UserProfileDetails mt-1"
                  >
                    Add Supplement
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
               <b-col md='12'
                  v-if="calanderLoading"
                  style="min-height: 400px"
                  class="d-flex justify-content-center align-items-center"
                >
                  <b-spinner label="Loading..."></b-spinner>
                </b-col>
                <b-col md="12" v-else>
                  <Calendar :calendar_data="calendar_data" />
                </b-col>
              </b-row>
              <b-modal
                hide-footer
                v-model="addSupplement"
                no-close-on-backdrop
                centered
                size="md"
              >
                <b-row class="p-2">
                  <b-col md="12">
                    <h2 class="mb-5 text-center font-weight-bolder fontfamily">
                      Assign Supplement to Client
                    </h2>
                  </b-col>
                  <b-col md="12">
                    <h3 for="" class="font-weight-bold fontfamily">
                      {{ AllSupplement.length }} Supplement
                    </h3>
                    <!-- <span v-if="massage" style="color: red">{{ massage }}</span> -->
                  </b-col>
                  <b-col
                    md="12"
                    v-for="(item, index) in AllSupplement"
                    :key="index"
                    class="mt-2"
                    style="max-height: 100px; overflow-y: auto"
                  >
                    <div class="d-flex w-100 align-items-center">
                      <div class="w-100">
                        <h4 class="m-0 userProfileDetails">
                          {{ item.name }}
                        </h4>
                      </div>
                      <div
                        class="d-flex w-100 align-items-center justify-content-end"
                      >
                        <b-button
                          @click="toggleSelection(item)"
                          :variant="
                            selectedSupplement.indexOf(item.id) !== -1
                              ? 'danger'
                              : 'primary'
                          "
                        >
                          <span class="font-weight-bold userProfileDetails"
                            >{{
                              selectedSupplement.indexOf(item.id) !== -1
                                ? "REMOVE"
                                : "ADD"
                            }}
                          </span>
                        </b-button>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-col md="12" class="d-flex justify-content-center mt-3">
                  <b-button
                    type="submit"
                    variant="primary"
                    class="font-weight-bold mr-1 userProfileDetails"
                    @click="submitSelectedCustomers"
                  >
                    Submit
                  </b-button>
                  <b-button
                    type="reset"
                    variant="primary"
                    class="font-weight-bold userProfileDetails"
                    @click="Close"
                  >
                    Cancel
                  </b-button>
                </b-col>
              </b-modal>
            </b-card>
          </b-tab>
          <b-tab>
            <template #title>
              <span>Uploaded Videos</span>
            </template>
            <b-row>
              <b-col cols="12" v-if="videoMessage">
                <div class="videoMessage">
                  {{ videoMessage }}
                </div>
              </b-col>
              <b-col
                cols="12"
                md="4"
                v-for="(item, index) in videosArray"
                :key="index"
              >
                <b-card
                  style="
                    position: relative;
                    text-align: start !important;
                    border-radius: 15px;
                  "
                  class="videomanagement text-light"
                >
                  <label
                    class="d-flex align-items-end"
                    style="
                      background-color: rgba(47, 79, 79, 0.4);
                      width: 23%;
                      border-radius: 5px;
                      position: absolute;
                      color: #fff;
                      z-index: 10;
                      padding: 5px 10px;
                      right: 5%;
                      top: 3%;
                    "
                  >
                    5 min
                  </label>
                  <div style="height: 180px">
                    <b-img
                      v-if="isImage(item.url)"
                      class="w-100 h-100 text-dark"
                      style="border-radius: 10px 10px 0px 0px"
                      :src="item.url"
                      :alt="item.thumbnail"
                    />
                    <video
                      v-else
                      class="w-100 h-100 text-dark"
                      style="border-radius: 10px 10px 0px 0px"
                      ref="videoPlayer"
                      controls
                    >
                      <source :src="item.url" type="video/mp4" />
                    </video>
                  </div>

                  <!-- <button @click="playPauseVideo">{{ isPlaying ? 'Pause' : 'Play' }}</button> -->

                  <div
                    class="bg-dark p-2"
                    style="border-radius: 0px 0px 10px 10px"
                    :style="!isImage(item.url) ? 'margin-top:-6px' : ''"
                  >
                    <div
                      class="text-muted"
                      style="font-size: 15px; margin-top: -15px"
                    >
                      {{ item && item.user ? item.user.name : "" }}
                    </div>
                    <h4 class="text-white font-weight-bolder mt-1">
                      "{{ item.title }}"
                    </h4>
                    <div
                      class="d-flex justify-content-between align-items-center mt-1"
                    >
                      <div class="text-muted" style="font-size: 12px">
                        {{ kFormatter(view_count ? view_count : 0) }} views •
                        {{
                          moment(
                            item.created_at.split("T")[0].split("-").join(""),
                            "YYYY-MM-DD"
                          ).fromNow()
                        }}
                      </div>
                      <b-button
                        @click="removeVideo(item.id)"
                        variant="outline-danger"
                        size="sm"
                        style="margin-left: 5px"
                      >
                        Remove
                      </b-button>
                    </div>
                  </div>
                  <!--/ follower projects rank -->
                </b-card>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-col>
      <div>
        <b-modal
          v-model="comparisonModal"
          no-close-on-backdrop
          @hidden="handleCloseComp"
          hide-footer
          centered
          size="xl"
          title="Photo Comparison"
        >
          <Comparison :comparisonArray='comparisonArray'/>
        </b-modal>
        <b-modal
          v-model="planRenew"
          no-close-on-backdrop
          hide-footer
          centered
          size="md"
        >
          <validation-observer ref="planRenewal">
            <b-form ref="newPlan">
              <b-row class="p-1">
                <b-col md="12" class="my-2 text-center">
                  <h3
                    style="
                      font-weight: 600;
                      font-size: 26px;
                      font-family: Public Sans;
                    "
                  >
                    Renew Plan
                  </h3>
                </b-col>
                <b-col md="12">
                  <label for="input">Select Plans</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Select Plan"
                  >
                    <v-select
                      placeholder="Select Your Plans"
                      label="name"
                      :searchable="false"
                      :options="getOptions('plan')"
                      v-model="plans"
                    ></v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>

                <!-- @click="Congo" -->
                <b-col md="12" class="d-flex justify-content-center mt-2">
                  <b-button
                    type="submit"
                    variant="primary"
                    class="mr-1"
                    @click.prevent="handleFormSubmission()"
                    style="font-family: Public Sans"
                  >
                    Submit
                  </b-button>
                  <b-button
                    type="reset"
                    @click="CloseModal()"
                    variant="primary"
                    style="font-family: Public Sans"
                  >
                    Cancel
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-modal>
      </div>
    </b-row>
  </div>
</template>
  
<script>
import {
  BButton,
  BTabs,
  BImg,
  BTab,
  BCardText,
  BTable,
  BCard,
  BAvatar,
  BPagination,
  BCol,
  BRow,
  BFormInput,
  BFormGroup,
  BProgress,
  BCardBody,
  BForm,
  BMedia,
  BMediaAside,
  BModal,
  BBadge,
  BSpinner,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import VueApexCharts from "vue-apexcharts";
import AppEchartStackedArea from "@core/components/charts/echart/AppEchartStackedArea.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Calendar from "./calendar/Calendar.vue";
import Workout from "./videoManagement/workoutVideoManagement.vue";
import axios from "@/component/axios";
import { kFormatter } from "../../../@core/utils/filter";
import moment from "moment";
import _ from "lodash";
import { required } from "@validations";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Comparison from './Comparison.vue'
// import { Calendar } from '@fullcalendar/core'

export default {
  components: {
    BMedia,
    BMediaAside,
    BBadge,
    BCardText,
    BPagination,
    BSpinner,
    BModal,
    BCardBody,
    ValidationProvider,
    ValidationObserver,
    required,
    BTabs,
    BTab,
    BCard,
    BAvatar,
    BForm,
    BCol,
    BImg,
    BFormInput,
    BButton,
    BProgress,
    BRow,
    vSelect,
    BTable,
    BFormGroup,
    VueApexCharts,
    AppEchartStackedArea,
    Calendar,
    Workout,
    flatPickr,
    BFormCheckbox,
    Comparison
  },
  data() {
    return {
      calanderLoading:false,
      // tableColumns: ['CheckIn', 'CurrentPhoto', 'CurrentWeight', 'CurrentWaistMeasurement', 'WeaklyProgress', 'Remark'],
      searchTerm: { name: "Client", value: "customer" },
      searchType: { name: "Trainer", value: "trainer" },
      searchTrainer: "",
      search: "",
      chartFlag: false,
      date:null,
      tableColumns: [
        { key: "id", label: "", sortable: false },
        // { key: 'invoiceStatus', sortable: true },
        {
          key: "checkintime",
          label: "CheckIn",
          sortable: true,
        },
        { key: "CurrentPhoto", label: "Current Photo" },
        { key: "CurrentWeight", label: "Current Weight", sortable: true },
        // { key: 'total', sortable: true, formatter: val => `$${val}` },
        {
          key: "CurrentWaistMeasurement",
          label: "Current Waist",
          sortable: true,
        },
         {
          key: "currentchest",
          label: "Current Chest",
          sortable: true,
        },
        // { key: 'balance', sortable: true },
        { key: "WeaklyProgress", label: "Weekly Progress", sortable: true },
        { key: "Remark", label: "Remark" },
      ],
      trainer: {},
      // data: [],
      // tabledata:[],
      isSortDirDesc: true,
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      sortBy: "",
      orderBy: true ? "ASC" : "DESC",
      kFormatter,
      changetrainer: false,
      moment,
      isDataLoading: false,
      SupplementBrandValue: "",
      SupplementNameValue: "",
      DescriptionValue: "",
      CommentValue: "",
      per_page: 10,
      addSupplement: false,
      Addsupple: false,
      isSideDataLoading: false,
      Supple: "",
      AllSupplement: [],
      trainier: "",
      suspend: false,
      trainee: false,
      selected: null,
      view_count: "",
      videosArray: [],
      baseApi: process.env.VUE_APP_APIENDPOINT,
      trainersList: [],
      paginationStart: 0,
      paginationEnd: 0,
      paginationValue: 1,
      paginationTotal: 0,
      option: {
        xAxisData: [],
        series: [
          {
            type: "line",
            stack: "Total",
            areaStyle: {
              color: "rgba(255, 229, 7, 1)",
            },
            color: "#299aff",
            showSymbol: false,
            smooth: 0.4,
            lineStyle: {
              width: 0,
            },
            data: [],
            // data: [20, 60, 65, 40, 75, 50, 85, 60, 95, 100],
          },
        ],
      },
      columnFilters: {},
      details: [],
      userData: {},
      allTrainer: [],
      AssignTrainer: {},
      videoMessage: "",
      MembershipDate: [],
      MembershipStartDate: [],
      selectedSupplement: [],
      SupplementComment: [],
      supplementData: [],
      suspened: false,
      suspendWeak: "",
      SuspendReason: "",
      Parmasuspended: false,
      ParmaSuspendReason: "",
      planRenew: false,
      selectWeakOptions: [
        { label: "1 Week", value: "7 days" },
        { label: "2 Week", value: "14 days" },
      ],
      calendar_data: [],
      plans: "",
      selectedID: 0,
      originalDate: moment().format("DD/MM/YYYY"),
      storeTainer: [],
      comparisonArray: [],
      comparisonModal: false,
    };
  },
  computed: {
    isProfileType() {
      return this.$route.query.type === "profile";
    },
    isMembershipDateAfterToday() {
      return moment(new Date(this.MembershipDate)) < moment(new Date());
    },
    setSpecialization() {
      return (trainer) => {
        if (trainer) {
          let arr = trainer.all_specialization.map((item) => {
            return item.specialization ? item.specialization.name : "";
          });

          return arr.filter((item) => item).join(", ");
        }
        return "";
      };
    },
  },

  watch: {
    // '$store.state.app.trainers':{
    //   handler:'getallTrainer',
    //   immediate:true
    // }
  },
  mounted() {
    this.getVideo();
    this.getClientData();
    this.getallTrainer();
    this.getAllSupplement();
    this.getWorkoutPlan();
    this.getProgressData();
  },
  methods: {
    handleDateChange: _.debounce(function (e){
      let arr =[]
      if(e){
        arr=e.split(' to ')
      }
      this.getClientData(arr)
    },2000),
    handleOpenImage(item){
      window.open(item,'_bank')
    },
    handleCloseComp() {
      this.comparisonArray.map((item) => {
        item.checked = false;
      });
      this.comparisonArray = [];
    },
    handleCheck(e, item) {
      if (e) {
        if(this.comparisonArray.length ==1 && this.comparisonArray[0].id > item.id){
          this.comparisonArray.splice(0,0,item)
        }
        else{
          this.comparisonArray.push(item);
        }
        if (this.comparisonArray && this.comparisonArray.length == 2) {
          this.comparisonModal = true;
        }

        (this.comparisonArray);
      } else {
        let idx = this.comparisonArray.findIndex(
          (inner) => inner.id == item.id
        );
        this.comparisonArray.splice(idx, 1);
      }
    },
    handleFormSubmission() {
      this.$refs.planRenewal.validate().then(async (success) => {
        if (success === false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Please fill in all fields",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.planRenewable();
        }
      });
    },
    planRenewable() {
      let endDate;

      switch (this.plans.id) {
        case 1:
          endDate = moment(this.originalDate, "DD/MM/YYYY")
            .add(1, "month")
            .format();
          break;
        case 2:
          endDate = moment(this.originalDate, "DD/MM/YYYY")
            .add(3, "months")
            .format();
          break;
        case 3:
          endDate = moment(this.originalDate, "DD/MM/YYYY")
            .add(6, "months")
            .format();
          break;
        case 4:
          endDate = moment(this.originalDate, "DD/MM/YYYY")
            .add(9, "months")
            .format();
          break;
        case 5:
          endDate = moment(this.originalDate, "DD/MM/YYYY")
            .add(12, "months")
            .format();
          break;
        default:
          // Handle other cases or set a default behavior
          break;
      }
      let Id = this.$route.params.id;

      const customerData = {
        id: Id,
        startdate: moment().format("YYYY-MM-DD"),
        enddate: moment(endDate).format('YYYY-MM-DD'),
        plan_id: this.plans.id,
        action:'Approve'

      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}dashboard/approve-reject-renewal-plan/${Id}`,
        data: JSON.stringify(customerData),
      };
      axios(requestOptions)
        .then((response) => {
          this.$swal({
            title: "Submitted",
            text: response.data.massage
              ? `${response.data.massage}`
              : "Client Updated Successfully",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.getClientData();
          this.planRenew = false;
          this.$forceUpdate();
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.$swal({
              title: "Error!",
              text: error.response.data.message,
              icon: "error",
              timer: 5000,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          } else {
            this.$swal({
              title: "Error!",
              text: `${error}`,
              icon: "error",
              timer: 5000,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
        });
    },
    CloseModal() {
      this.planRenew = false;
    },
    onSortChanged(e) {
      this.sortBy = e.sortBy;
      this.orderBy = e.sortDesc ? "DESC" : "ASC";
      // console.log(this.sortBy)
      // console.log(this.orderBy)
      this.getClientData();
    },
    resetDate() {
      this.search = "";
    },
    Supplement() {
      this.Addsupple = !this.Addsupple;
      this.addSupplement = !this.addSupplement;
    },
    Close() {
      this.Addsupple = false;
      this.addSupplement = false;
    },
    changePagination() {
      this.getClientData();
    },
    // filterTable: _.debounce(function () {
    //   this.params = {};
    //   for (let key in this.columnFilters) {
    //     if (
    //       this.columnFilters[key] &&
    //       typeof this.columnFilters[key] == "object"
    //     ) {
    //       this.params[key] = this.columnFilters[key]
    //         .map((inner) => inner.id)
    //         .join(",");
    //     } else {
    //       this.params[key] = this.columnFilters[key];
    //     }
    //   }
    //   this.params.page = this.paginationValue;
    //   this.getClientData();
    //   this.getallTrainer();
    // }, 1000),
    handleSearch: _.debounce(function () {
      // this.getClientData();
      this.getallTrainer();
    }, 1000),

    toggleSelection(item) {
      let index = this.selectedSupplement.indexOf(item.id);
      if (index == -1) {
        this.selectedSupplement.push(item.id);
      } else {
        this.selectedSupplement.splice(index, 1);
      }
      this.$forceUpdate();
    },
    handleTrainerSubmit(e, id) {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success === false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Please fill in all fields",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.handleSuspendTrainer(
            e,
            id,
            this.suspendWeak,
            this.SuspendReason
          );
          this.suspened = false;
        }
      });
    },
    async handleSuspendTrainer(e, id, week, reason) {
      const customerData = {
        suspend_type: e,
        user_id: id,
        days: week ? week.value : "",
        suspend_reason: reason,
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}dashboard/suspend-user`,
        data: JSON.stringify(customerData),
      };
      await axios(requestOptions)
        .then((response) => {
          this.$swal({
            title: "Submitted",
            text: response.data.message
              ? `${response.data.message}`
              : response.data.success
              ? `${response.data.success}`
              : "Password Updated Successfully",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.$router.push({ path: `/clientmanagement` });
          this.$forceUpdate();
          this.assignTrainer = true;
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.$swal({
              title: "Error!",
              text: error.response.data.message,
              icon: "error",
              timer: 5000,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          } else {
            this.$swal({
              title: "Error!",
              text: `${error}`,
              icon: "error",
              timer: 5000,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
        });
    },
    async submitSelectedCustomers() {
      const customerData = {
        supplement_id: this.selectedSupplement,
      };
      const requestOptions = {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}customer/assign-supplement/${this.$route.params.id}`,
        data: JSON.stringify(customerData),
      };
      await axios(requestOptions)
        .then((response) => {
          this.$swal({
            title: "Submitted",
            text: response.data.message
              ? `${response.data.message}`
              : response.data.success
              ? `${response.data.success}`
              : "Update Successfully",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.getWorkoutPlan();
          this.addSupplement = false;
          this.getWorkoutPlan();
          // this.selectedSupplement = [];
          this.$forceUpdate();
        })
        .catch((error) => {
          console.error("Error adding customer:", error);
        });
    },
    async getWorkoutPlan() {
      this.calanderLoading=true
      await axios({
        method: "GET",
        url: `${this.baseApi}customer/get-workout/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.supplementData = json?.data.data.supplement;
          this.calendar_data = json?.data.data;
          this.calanderLoading=false
        })
        .catch((error) => console.log(error, "error for getting supplement"));
    },
    async getProgressData() {
      await axios({
        method: "GET",
        url: `${this.baseApi}customer/get-progress/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          // console.log(json, "progress");
          let data = json.data.data.progress_data;
          this.option.xAxisData = data.xAxisData.map((item) => {
            return moment(item).format("DD/MM/YYYY");
          });
          this.option.series[0].data = data.series;
        })
        .catch((error) => console.log(error, "error for getting progressData"))
        .finally(() => {
          this.chartFlag = true;
        });
    },
    async getVideo() {
      await axios({
        method: "GET",
        url: `${this.baseApi}video/get-customer/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((response) => {
          if (!response.data.message) {
            this.videosArray = response.data;
            this.videoMessage = "";
          } else {
            this.videoMessage = response.data.message;
          }
        })
        .catch((error) => console.log(error, "error for getting videos"));
    },
    async getClientData(arr) {
      this.isSideDataLoading = true;
      let params = {};
      params.page = this.paginationValue;
      params.per_page = this.per_page;
      if (this.search) {
        params.search = this.search;
        params.type = this.searchTerm.value;
      }
      if (this.sortBy) {
        params.sortBy = this.sortBy;
        params.orderBy = this.orderBy;
      }
      if(arr && arr.length==2){
        params.start = moment(arr[0],'DD/MM/yyyy').format('yyyy-MM-DD');
        params.end = moment(arr[1],'DD/MM/yyyy').format('yyyy-MM-DD');
      }
      // let params = `?page=${this.paginationValue}&per_page=${this.per_page}${
      //   this.search ? "&search=" + this.search : ""
      // }`;
      await axios({
        method: "GET",
        url: `${this.baseApi}customer/show/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        params: params,
      })
        .then((json) => {
          this.data = json?.data?.data;
          this.details = json?.data.data.userDetails.data;
          this.userData = json.data.data.user;
          this.userData.weekly_goal = this.userData.customer_goal
            ? this.userData.customer_goal.weeklygainweight
              ? this.userData.customer_goal.weeklygainweight.name
              : this.userData.customer_goal.weeklyloseweight
              ? this.userData.customer_goal.weeklyloseweight.name
              : ""
            : "";
          this.userData.goal_name =
            this.userData.customer_goal && this.userData.customer_goal.goal
              ? this.userData.customer_goal.goal.name
              : "";
          this.MembershipDate =
            json && json.data && json.data.data && json.data.data.customerPlan
              ? json.data.data.customerPlan.enddate
              : "";
          this.MembershipStartDate =
            json && json.data && json.data.data && json.data.data.customerPlan
              ? json.data.data.customerPlan.startdate
              : "";
          this.AssignTrainer = json.data.data.user.trainer;
          this.paginationTotal = json?.data.data.userDetails.total;
          this.paginationStart = json?.data.data.userDetails.from;
          this.paginationEnd = json?.data.data.userDetails.to;
          //     ? json.data.data[0]
          //     : json.data.data;
        })
        .catch((error) => console.log(error, "error"))
        .finally(() => {
          this.isSideDataLoading = false;
        });
    },
    async removeVideo(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then(async (result) => {
          if (result.value) {
            await axios({
              method: "Delete",
              url: `${this.baseApi}video/delete/${id}`,
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.accessToken}`,
              },
            })
              .then(() => {
                const index = this.videosArray.findIndex(
                  (item) => item.id === id
                );
                if (index !== -1) {
                  this.videosArray.splice(index, 1);
                  this.$swal({
                    title: "Deleted",
                    text: "Client deleted successfully",
                    icon: "success",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                  });
                  this.$forceUpdate();
                  this.getClientData();
                }
              })
              .catch((error) => {
                console.error("Error deleting user:", error);
              });
          }
        })
        .catch((error) => console.log(error, "error"))
        .finally(() => {});
    },
    async getallTrainer() {
      this.isDataLoading = true;
      // `?page=${this.paginationValue}&per_page=${this.per_page}${
      //   this.search ? "&search=" + this.search : ""
      // }`;
      let params ={}
      if(this.searchTrainer){
        params.search= this.searchTrainer;
        params.type=this.searchType.value
      }

      await axios({
        method: "GET",
        url: `${this.baseApi}trainer/get-all-trainer`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        params:params
      })
        .then((json) => {
          this.allTrainer = json?.data.data;
        })
        .catch((error) => console.log(error, "error"))
        .finally(() => {
          this.isDataLoading = false;
        });

      // this.storeTainer = this.$store.state.app.trainers;

      //   if(this.searchTrainer){
      //     this.allTrainer = this.storeTainer.filter((item)=>{
      //       let regex = new RegExp(this.searchTrainer, 'i');
      //       return regex.test(item.f_name)
      //     })
      //   }
      //   else{
      //     this.allTrainer = this.storeTainer.slice()
      //   }
      //     console.log(this.allTrainer,this.search)
    },
    async getAllSupplement() {
      await axios({
        method: "GET",
        url: `${this.baseApi}supplement/get-all-supplement`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.json;
          this.AllSupplement = json?.data.supplement;
          // console.log(this.SupplementComment, "supplement Comment");
        })
        .catch((error) => console.log(error, "error"));
    },

    isImage(data) {
      const imageExtensions = [
        ".jpg",
        ".jpeg",
        ".png",
        ".gif",
        ".bmp",
        ".webp",
        ".svg",
        ".tiff",
      ];
      return imageExtensions.some((extension) =>
        String(data).endsWith(extension.toLowerCase())
      );
    },
    formatDate(checkindate) {
      return new Date(checkindate).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },

    openModal() {
      this.changetrainer = true;
    },
    openPlanModal() {
      this.planRenew = true;
    },
    openSuspendModal() {
      this.suspened = true;
      this.SuspendReason = "";
      this.suspendWeak = "";
    },

    Supplement() {
      this.selectedSupplement = [];
      this.addSupplement = true;
    },
    async removeSupplement(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then(async (result) => {
          if (result.value) {
            await axios({
              method: "Delete",
              url: `${this.baseApi}customer/delete-customer-supplement/${id}`,
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.accessToken}`,
              },
            })
              .then(() => {
                const index = this.supplementData.findIndex(
                  (item) => item.id === id
                );
                this.supplementData.splice(index, 1);
                this.$swal({
                  title: "Deleted",
                  text: "Supplement deleted successfully ",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
                this.$forceUpdate();
                this.getWorkoutPlan();
              })
              .catch((error) => {
                console.error("Error deleting user:", error);
              });
          }
        })
        .catch((error) => console.log(error, "error"))
        .finally(() => {});
    },

    async selectTrainer(id) {
      let data = {
        trainer_id: id,
      };
      this.isDataLoading=true
      await axios({
        method: "POST",
        url: `${this.baseApi}customer/assign-trainer/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: data,
      })
        .then((json) => {
          this.$swal({
            title: "Submitted",
            text: json.data.message
              ? `${json.data.message}`
              : json.data.success
              ? `${json.data.success}`
              : "Trainer Assign Successfully",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.changetrainer = false;
          this.getClientData();
        })
        .catch((error) => console.log(error, "error"))
        .finally(()=>{
          this.isDataLoading=false
        })
    },
    async handleParmaSuspendTrainer(e, id) {
      const customerData = {
        suspend_type: e,
        user_id: id,
        suspend_reason: this.ParmaSuspendReason,
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}dashboard/suspend-user`,
        data: JSON.stringify(customerData),
      };
      await axios(requestOptions)
        .then((response) => {
          this.$swal({
            title: "Submitted",
            text: response.data.message
              ? `${response.data.message}`
              : response.data.success
              ? `${response.data.success}`
              : "Client Suspend Successfully",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.$router.push({ path: `/clientmanagement` });
          (this.Parmasuspended = false), this.$forceUpdate();
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.$swal({
              title: "Error!",
              text: error.response.data.message,
              icon: "error",
              timer: 5000,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          } else {
            this.$swal({
              title: "Error!",
              text: `${error}`,
              icon: "error",
              timer: 5000,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
        });
    },
    closemodel() {
      this.Parmasuspended = false;
      this.suspened = false;
      this.ParmaSuspendReason = "";
    },
    openNewModal() {
      this.Parmasuspended = true;
      this.ParmaSuspendReason = "";
    },
  },
};
// if (this.changetrainer) {
//     this.changetrainer = false
// }
// this.trainee = true
</script>

<style scoped>
.customer_btable_td {
  padding: 1px !important;
}

.fontfamily {
  font-family: "Public Sans", sans-serif;
  font-size: 22px;
}

.userProfileDetails {
  font-size: 15px;
  font-family: "Public Sans", sans-serif;
}

.videoMessage {
  width: 100%;
  height: 200px;
  display: grid;
  place-items: center;
}

.lableTitle {
  font-weight: 600;
  font-family: "Public Sans", sans-serif;
  font-size: 15px;
}

.inputValue {
  font-family: "Public Sans", sans-serif;
}
.checkindate {
  min-width: 73px;
}
.suppli-intake {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}
.pose-img {
  max-height: 300px;
  width: 50%;
}
@media (max-width:770px){

.pose-img {
  width: 70% !important;
}
}
@media (max-width:520px){

 .weight-list{
  font-size: 15px !important;
  padding-left: 5px;
   border-left: 2px solid #28c76f !important;
 }
 .red-weight-list{
    border-left: 2px solid #ea5455 !important;
    font-size: 15px !important;
    padding-left: 5px;
  }
  .comp-border{
    padding: 5px !important;
  }
  .modal-body{
    padding: 10px !important;
  }
  .comp-date{
    font-size: 15px !important;

  }
}
.gap-1 {
  gap: 10px;
}
.weight-details {
  font-size: 20px;
}
.red-weight-list{
  border-left: 4px solid #ea5455 !important;
  padding-left: 10px;

}
.weight-list {
  border-left: 4px solid #28c76f;
  padding-left: 10px;
}
.weight-list span ,.red-weight-list span  {
  font-weight: bold;
}
</style>
<!-- .comp-border{
  border-right: 1px solid rgb(178, 176, 176);
} -->